// import ReactGA from 'react-ga'
// import TagManager from 'react-gtm-module'

export const FOLDER_TYPE = {
	SHARED: 'SHARED',
	FOLDER: 'FOLDER',
	FILES: 'FILES',
}
export const DOCS_ACCESS_TYPES = {
	SUPER_ADMIN: 'SUPER_ADMIN',
	ADMIN: 'ADMIN',
	VIEWER: 'VIEWER',
}
export const USER_SEARCH_TYPES = {
	ORG: 1,
	ROLE: 2,
	WORKFORCE: 3,
}
// export const initializeTracking = () => {
// 	const isDev = false
// 	ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, { debug: isDev })
// }

// export const pageTrack = () => {
// 	ReactGA.pageview(`${window.location.pathname}${window.location.search}`)
// }

const getCategoryAndAction = (categoryId, actionId) => {
	const obj = { category: '' }
	switch (categoryId) {
		case 0:
			obj.category = 'Files'
			if (actionId >= 0)
				switch (actionId) {
					case 0:
						obj.action = 'Add new file'
						break
					case 1:
						obj.action = 'Activity Selected'
						break
					case 2:
						obj.action = 'Activity Details Menu Selected'
						break
					case 3:
						obj.action = 'Sending EMail'
						break
					case 4:
						obj.action = 'Adding Comment in File Timeline'
						break
					case 5:
						obj.action = 'File Upload'
						break
					case 6:
						obj.action = 'Open Participant Modal'
						break
					case 7:
						obj.action = 'Workflow Search'
						break
					case 8:
						obj.action = 'Add Participant'
						break
					default:
						obj.action = 'Button Clicked'
						break
				}
			break
		case 1:
			obj.category = 'Meetings'
			if (actionId >= 0)
				switch (actionId) {
					case 0:
						obj.action = 'Add New Meeting'
						break
					default:
						break
				}
			break
		case 3:
			obj.category = 'Export'
			if (actionId >= 0)
				switch (actionId) {
					case 0:
						obj.action = 'Apply Filters'
						break
					case 1:
						obj.action = 'Save Filters'
						break
					default:
						break
				}
			break
		case 4:
			obj.category = 'Report'
			if (actionId >= 0)
				switch (actionId) {
					case 0:
						obj.action = 'Report Generation'
						break
					default:
						break
				}
			break
		case 5:
			obj.category = 'Documents'
			if (actionId >= 0)
				switch (actionId) {
					case 0:
						obj.action = 'Document Repository Tab'
						break
					case 1:
						obj.action = 'Vidm Document Tab'
						break
					case 2:
						obj.action = 'Document Tab'
						break
					default:
						break
				}
			break

		default:
			break
	}
	return obj
}

export const eventLabelTrack = payload => {
	const eventPayload = {
		...getCategoryAndAction(payload.categoryId, payload.actionId),
		label: payload.label,
	}
	// ReactGA.event(eventPayload)
}

export const eventLabelActionTrack = payload => {
	const eventPayload = {
		...getCategoryAndAction(payload.categoryId),
		action: payload.action,
		label: payload.label,
	}
	// ReactGA.event(eventPayload)
}

export const sectionDetailedTrack = ({
	category,
	action,
	label,
	nonInteraction,
	transport,
}) => {
	category = `${category}`
	// ReactGA.event({
	//   category,
	//   action,
	//   label,
	//   nonInteraction,
	//   transport,
	// })
}

// export const dataLayerTagManager = (event, objArgs) => {
//   const args = {
//     dataLayer: {
//       event,
//       ...objArgs,
//       /* can pass more variables here if needed */
//     },
//   }
//   TagManager.dataLayer(args)
// }

/**
 *
 * @param {*} label: where the link is redirecting to.
 */

export const redirectTrack = ({ label }) => {
	// ReactGA.outboundLink({
	// 	label,
	// })
}

export const createDocumentPath = folders => {
	let path = ''
	folders
		.sort((a, b) => a.index - b.index)
		.forEach(folder => {
			path += `${folder.label.toLowerCase().split(' ').join('_')}/`
		})
	return path
}

export const reorganizeDocs = (contentList, asset_id) => {
	let sharedFolderList = []
	let folderList = []
	let fileList = []
	;(contentList || []).forEach(content => {
		if (!!content.creator_asset_id && content.creator_asset_id !== asset_id) {
			sharedFolderList.push(content)
		} else {
			if (
				!!content.document_repository_sub_type_id &&
				content.document_repository_sub_type_id === 1
			) {
				// 1 - file
				fileList.push(content)
			} else {
				// 0 - Folder
				folderList.push(content)
			}
		}
	})

	fileList = fileList.sort((a, b) => {
		let aDate = new Date(
			a.activity_datetime_created.split(' ').join('T')
		).getTime()
		let bDate = new Date(
			a.activity_datetime_created.split(' ').join('T')
		).getTime()
		return aDate > bDate
	})

	return {
		sharedFolderList,
		folderList,
		fileList,
	}
}

export const reOrganizeSharedUsers = userListResp => {
	const userListFinal = userListResp.map(user => {
		let asset_id, asset_name
		let sub_title = ''
		if (user.document_access_level_id === 6) {
			/// users
			asset_id = user.asset_id
			asset_name = user.operating_asset_first_name
		} else if (user.document_access_level_id === 5) {
			/// rolese
			asset_id = user.asset_type_id
			asset_name = user.asset_type_name
		} else {
			/// rolese
			asset_id = user.workforce_id
			asset_name = user.workforce_name
			sub_title = user.account_name
		}
		return {
			sub_title,
			asset_id: asset_id,
			asset_name: asset_name,
			asset_country_code: user.operating_asset_phone_country_code,
			asset_phone: user.operating_asset_phone_number,
			asset_type_id: user.asset_type_id,
			asset_type_name: user.asset_type_name,
			document_access_level_id: user.document_access_level_id,
			access_level: user.asset_participant_access_name,
		}
	})
	return userListFinal
}

export const GA_CATEGORY_MENTION_REPLY = 'GA_CATEGORY_MENTION_REPLY'
export const GA_CATEGORY_ACTIVITY_VIEW = 'GA_CATEGORY_ACTIVITY_VIEW'
export const GA_CATEGORY_FORM_VIEW = 'GA_CATEGORY_FORM_VIEW'
export const GA_CATEGORY_FORM_OPEN = 'GA_CATEGORY_FORM_OPEN'
export const GA_CATEGORY_FORM_SUBMIT = 'GA_CATEGORY_FORM_SUBMIT'
export const GA_CATEGORY_DASHBOARD_VIEW = 'GA_CATEGORY_DASHBOARD_VIEW'
export const GA_CATEGORY_DOCS_VIEW = 'GA_CATEGORY_DOCS_VIEW'
export const GA_CATEGORY_VIDM_VIEW = 'GA_CATEGORY_VIDM_VIEW'
export const GA_CATEGORY_FILTER_VIEW = 'GA_CATEGORY_FILTER_VIEW'

export const GA_CATEGORY_THEME = 'Theme'
export const GA_CATEGORY_FILES = 'Files'
export const GA_CATEGORY_MEETINGS = 'Meetings'
export const GA_CATEGORY_REPORT = 'Report'
export const GA_CATEGORY_REPOSITORY = 'Repository'
export const GA_CATEGORY_VIDM = 'Vidm'
export const GA_CATEGORY_WORKHOURS = 'Workhours'
export const GA_CATEGORY_WORKSPACE_PANEL = 'Workspace Panel'
export const GA_CATEGORY_DATA_MANAGEMENT = 'Data Management'
export const GA_CATEGORY_TEAM = 'Team'
export const GA_CATEGORY_GANTT = 'Gantt'
export const GA_CATEGORY_EXPORT = 'Export'
export const GA_CATEGORY_DIALOG_BOX = 'Dialog Alert'
export const GA_CATEGORY_DASHBOARD = 'Dashboard'
export const GA_CATEGORY_TOP_DOWN = 'Top Down'
export const GA_CATEGORY_CUSTOM_INPUT = 'Custom Input'
export const GA_CATEGORY_LEAVE = 'Leave'
export const GA_CATEGORY_FORM_MANAGEMENT = 'Form Management'
export const GA_CATEGORY_NAVBAR = 'Navbar'
export const GA_CATEGORY_LOGIN = 'Login'
export const GA_CATEGORY_PAGINATION = 'Pagination'
export const GA_CATEGORY_TIMELINE = 'Timeline'
