import {
	Avatar,
	Badge,
	Box,
	Button,
	Divider,
	Flex,
	Heading,
	HStack,
	Center,
	SimpleGrid,
	Spinner,
	Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useHandleSuspension } from '../../hooks/team.hooks'
import { useLanguageContext } from '../../context/locale.context'
import * as utils from '../../utils/common.util'
import { formatDateTime } from '../../utils/date.utils'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../repository/repo.utils'
// import { GA_CATEGORY_TEAM } from './../repository/repo.utils'

export const optionList = [
	{
		label: 'Top Down',
		key: 'topDown',
		value: 0,
	},
	{
		label: 'Bottom Up',
		key: 'bottomUp',
		value: 1,
	},
]
const TeamList = ({ users, query, isLoading, reload, loadTeams }) => {
	const { mutate, data } = useHandleSuspension()
	const {
		state: { locale },
	} = useLanguageContext()
	const [selectedMember, setSelectedMember] = useState()
	const [topDown, setTopDown] = useState(null)
	const [selected, setSelected] = useState(optionList[0])
	const handleSuspension = (user, flag) => {
		setSelectedMember(user.asset_id)
		mutate(
			{
				target_asset_id: user.asset_id,
				asset_flag_suspended: flag,
				asset_suspension_datetime: formatDateTime(),
				asset_suspension_activity_id: 0,
			},
			{
				onSuccess: async () => {
					reload(user)
				},
				onError: async error => {
					console.error('Error while suspension', error)
				},
			}
		)
	}

	const handlePopUp = item => {
		setTopDown(item)
	}

	const onChange = val => {
		setSelected(val)
	}

	return (
		<Box
			p={2}
			h='70vh'
			w='full'
			overflowY='scroll'
			sx={{
				'::-webkit-scrollbar': {
					width: '3px',
				},
				'::-webkit-scrollbar-thumb': {
					outline: `1px solid ${localStorage.getItem('color')}`,
					borderRadius: '50px',
					border: 'solid 3px transparent',
					backgroundClip: 'content-box',
					WebkitBackgroundClip: 'content-box',
				},
			}}
		>
			{isLoading ? (
				<Center>
					<Spinner color='brand.900' size='lg' />
				</Center>
			) : !!users && !users.length ? (
				<Text color='#48BB78' fontSize='lg' fontWeight='bold'>
					{' '}
					{locale['No Reportees Found']}
				</Text>
			) : (
				<SimpleGrid spacing={1} columns={3}>
					{(users || [])
						.filter(item => {
							if (!query) {
								return true
							}
							if (
								!!item.operating_asset_first_name &&
								item.operating_asset_first_name
									.toLowerCase()
									.includes(query.toLowerCase())
							) {
								return true
							}
							return false
						})
						.map((user, index) => {
							return (
								<HStack
									key={user.operating_asset_id}
									m={2}
									transition='0.3s'
									p={1}
									boxShadow='sm'
									borderRadius='md'
									minH='30%'
									bg='white'
									minW='20%'
									maxW='90%'
									position='relative'
									_hover={{
										boxShadow: '2xl',
									}}
								>
									{/* <Box
                        style={{ position: 'absolute', top: 5, right: 0 }}
                        onClick={() => handlePopUp(user)}
                      >
                        <CustomMenu
                          optionList={optionList}
                          selected={selected}
                          isFilter={true}
                          mx={2}
                          onSelect={item => {
                            onChange(item);
                          }}
                          icon={MdMoreVert}
                        />
                      </Box> */}
									<Flex w='40%' justifyContent='space-between'>
										<Box p='0' alignContent='left' w='100%'>
											<HStack alignContent='center'>
												<Avatar
													borderRadius='md'
													boxShadow='sm'
													size='md'
													name={
														user.operating_asset_id === 0
															? user.previous_operating_asset_first_name
															: user.operating_asset_first_name
													}
												/>
												{user.asset_flag_suspended === 0 &&
												user.operating_asset_id !== 0 ? (
													<Badge
														mx='5px'
														variant='outline'
														colorScheme={localStorage.getItem('color')}
														isTruncated
													>
														{locale['Active']}
													</Badge>
												) : user.operating_asset_id === 0 ? (
													<Badge
														mx='5px'
														variant='outline'
														colorScheme='red'
														isTruncated
													>
														{locale['Inactive']}
													</Badge>
												) : (
													<Badge
														mx='5px'
														variant='outline'
														colorScheme='red'
														isTruncated
													>
														{locale['Suspended']}
													</Badge>
												)}
											</HStack>
											<Heading
												my={1}
												size='sm'
												fontSize='sm'
												fontWeight='600'
												textTransform='capitalize'
												textDecoration='underline'
												color='#48BB78'
												cursor='pointer'
												onClick={() => {
													// sectionDetailedTrack({
													//   category: GA_CATEGORY_TEAM,
													//   action: 'Team List',
													//   label: locale['Loading Team List'],
													// })
													// dataLayerTagManager('button_click', {
													//   viewName: 'Team',
													//   buttonName: 'Select Reportee',
													// })
													loadTeams(user)
												}}
											>
												{user.operating_asset_id === 0
													? user.previous_operating_asset_first_name
													: user.operating_asset_first_name}
											</Heading>
											<Text
												fontSize='xs'
												textTransform='capitalize'
												color='black'
											>
												{user.asset_first_name}
											</Text>
											{!!user.asset_type_name && (
												<Text
													my={2}
													textAlign='left'
													fontSize='xs'
													fontWeight='bold'
													textTransform='capitalize'
													color='black'
												>
													{user.asset_type_name}
												</Text>
											)}
											<Text
												my={1}
												isTruncated
												textAlign='left'
												fontSize='xs'
												textTransform='capitalize'
												color='black'
											>
												{user.operating_asset_id === 0
													? user.previous_operating_asset_email_id
													: user.operating_asset_email_id}
											</Text>
											<Text my={2} fontSize='xs' textTransform='capitalize'>
												{user.operating_asset_id === 0
													? user.previous_operating_asset_phone_number
													: user.operating_asset_phone_number}
											</Text>
											{!!user.asset_type_flag_enable_suspension &&
											!!user.asset_flag_approval &&
											user.asset_flag_suspended === 1 ? (
												<Button
													size='xs'
													borderRadius='full'
													fontWeight='bold'
													variant='outline'
													colorScheme={localStorage.getItem('color')}
													onClick={() => {
														// sectionDetailedTrack({
														//   category: GA_CATEGORY_TEAM,
														//   action: 'Team List',
														//   label: locale['Activate'],
														// })
														// dataLayerTagManager('button_click', {
														//   viewName: 'Team',
														//   buttonName: 'Activate',
														// })
														handleSuspension(user, 0)
													}}
													autoFocus={
														user.asset_id === selectedMember ? true : false
													}
												>
													{locale['ACTIVATE']}
												</Button>
											) : !!user.asset_flag_approval &&
											  !!user.asset_type_flag_enable_suspension ? (
												<Button
													size='xs'
													borderRadius='full'
													fontWeight='bold'
													variant='outline'
													colorScheme='red'
													onClick={() => {
														// sectionDetailedTrack({
														//   category: GA_CATEGORY_TEAM,
														//   action: 'Team List',
														//   label: 'Suspend',
														// })
														// dataLayerTagManager('button_click', {
														//   viewName: 'Team',
														//   buttonName: 'Suspend',
														// })
														handleSuspension(user, 1)
													}}
													autoFocus={
														user.asset_id === selectedMember ? true : false
													}
												>
													{locale['SUSPEND']}
												</Button>
											) : null}
										</Box>
									</Flex>
									<Divider orientation='vertical' />
									<Box p='1' textAlign='left' justifyContent='left'>
										<Text
											as='span'
											my={2}
											fontSize='xs'
											textTransform='capitalize'
										>
											<Text as='span' fontSize='xs' fontWeight='600'>
												{locale['Segment']}: {user.workforce_name}
											</Text>
										</Text>
										{user.asset_created_datetime ? (
											<Text>
												<Text as='span' className='w-500' fontSize='xs'>
													{locale['Created at']} :{' '}
													{utils.timelineDateFormatShort(
														user.asset_created_datetime
													)}
												</Text>
											</Text>
										) : null}
										<Text my={2} fontSize='xs' textTransform='capitalize'>
											<Text as='span' fontWeight='600'>
												{locale['Circle']} :
											</Text>
											{user.account_name}
										</Text>
										{!!user.asset_last_seen_datetime ? (
											<Text my={2} fontSize='xs' textTransform='capitalize'>
												<Text as='span' fontWeight='600'>
													{locale['Last Seen']} :{' '}
												</Text>
												{utils.timeAgo(user.asset_last_seen_datetime)}
											</Text>
										) : null}

										{!!user.asset_last_location_address ? (
											<Text my={2} fontSize='xs' textTransform='capitalize'>
												<Text as='span' fontWeight='600'>
													{locale['Last Location']} :
												</Text>{' '}
												{user.asset_last_location_address}
											</Text>
										) : null}
										<Text my={2} fontSize='xs' textTransform='capitalize'>
											<Text as='span' fontWeight='600'>
												{locale['Manager']} :{' '}
											</Text>
											{user.manager_operating_asset_first_name}
										</Text>
									</Box>
								</HStack>
							)
						})}
				</SimpleGrid>
			)}
		</Box>
	)
}

export default TeamList
