import React, { useEffect, useState } from 'react'
import { Box, VStack, Skeleton, HStack, Icon, Text } from '@chakra-ui/react'
import { AiFillFolderOpen } from 'react-icons/ai'
import { GA_CATEGORY_REPOSITORY, sectionDetailedTrack } from '../repo.utils'
import { loadImageBlob } from '../../../utils/aws.util'
const encode = data => {
	let str = data.reduce(function (a, b) {
		return a + String.fromCharCode(b)
	}, '')
	return btoa(str).replace(/.{76}(?=.)/g, '$&\n')
}
const DocRepoData = ({ j, repository, image, onSelectRepository }) => {
	//console.log(image, 'docIcon')

	const [imageData, setImageData] = useState(null)
	const [isImageLoaded, setIsImageLoaded] = useState(false)

	useEffect(() => {
		// setTimeout(() => {
		if (image) {
			//console.log(loadImageBlob, 'docIcon111111')
			loadImageBlob(image, (data, type) => {
				let imgdata = 'data:' + type + ';base64,' + encode(data)
				setImageData(imgdata)
				setIsImageLoaded(true)
			})
		}
		// }, 2000)
	}, [image])

	return (
		<HStack
			key={repository.activity_type_id}
			alignItems='center'
			justifyContent='center'
			cursor='pointer'
			m={2}
			borderRadius='md'
			boxShadow='md'
			w='full'
			p={2}
			onClick={() => {
				sectionDetailedTrack({
					category: GA_CATEGORY_REPOSITORY,
					action: 'Repository List',
					label: 'Select Repository',
				})
				onSelectRepository(repository)
			}}
		>
			<VStack justifyContent='center'>
				<Box
					rounded='md'
					size='md'
					color={localStorage.getItem('color')}
					bg='secondary'
					p={1}
				>
					{/* <Icon as={AiFillFolderOpen} h={6} w={6} /> */}
					{isImageLoaded ? (
						<img src={imageData} alt={'Dashboard'} width={55} height={55} />
					) : (
						<Icon as={AiFillFolderOpen} h={6} w={6} />
					)}
				</Box>
			</VStack>
			<VStack alignItems='flex-start' justifyContent='space-around' w='full'>
				<Text fontWeight={500} my={0} fontSize='md'>
					{repository.activity_type_name}
				</Text>
			</VStack>
		</HStack>
	)
}

const RepositoryList = ({ repoList, onSelect, isLoading, selectedRepo }) => {
	const onSelectRepository = repository => {
		onSelect(repository)
	}
	console.log(repoList, 'repoList')
	return (
		<VStack spacing={2}>
			{isLoading
				? [1, 2, 3, 4].map(() => {
						return <Skeleton key={Math.random() * Date.now()} />
				  })
				: repoList &&
				  repoList
						.filter(doc => !!doc.activity_type_name)
						.map((repository, index) => {
							const isSelected =
								selectedRepo &&
								selectedRepo.activity_type_id === repository.activity_type_id
									? true
									: false
							let docIcon =
								repository && repository?.activity_type_inline_data
									? JSON.parse(repository?.activity_type_inline_data)
											.document_repository_icon
									: ''

							return (
								<>
									{repository.activity_type_name && (
										<DocRepoData
											key={`c${index}`}
											j={index}
											repository={repository}
											image={docIcon}
											onSelectRepository={onSelectRepository}
										/>
									)}
								</>
							)
						})}
		</VStack>
	)
}
export default RepositoryList
