/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Flex,
	Center,
	HStack,
	SimpleGrid,
	Skeleton,
	Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdDescription, MdFolder, MdFolderShared } from 'react-icons/md'
import Breadcrumb from '../../components/breadcrumb-menu'
import {
	useFolderContentList,
	useSearedDocs,
} from '../../hooks/documents.hooks'
import { useLanguageContext } from '../../context/locale.context'
import FileView from './filesView'
import FolderView from './foldersView'
import { FOLDER_TYPE } from './repo.utils'
import { RiFolderForbidFill } from 'react-icons/ri'
import { useAuthContext } from '../../context/auth.context'
import { DOCS_ACCESS_TYPES } from './repo.utils'

// import { getLocalAuthDetails } from '../utils/common.util';
export const DocumentsView = ({
	accessableRepositories,
	parentRepository,
	isLoading,
	selectedRepoData,
	onSelectRoot,
	refreshPage,
	query,
	searchType,
}) => {
	const { mutate: getFolderContentMutate, isLoading: isFoldersLoading } =
		useFolderContentList()
	const { mutate: getSearchDocsMutate, isLoading: isSearchedLoading } =
		useSearedDocs()

	const [breadCrumbMenu, setBreadCrumbMenu] = useState([])
	const [docTypes, setDocTypes] = useState(selectedRepoData)
	const {
		state: { locale },
	} = useLanguageContext()

	const {
		state: { authData },
	} = useAuthContext()

	useEffect(() => {
		setDocTypes(selectedRepoData)
	}, [selectedRepoData])
	useEffect(() => {
		if (!!query) {
			if (query === '' && !!parentRepository) {
				onSelectRoot(parentRepository)
			} else if (query.length) {
				getSearchedDocs(query)
			}
			setBreadCrumbMenu([])
		}
	}, [query])

	useEffect(() => {
		if (!!parentRepository) {
			let temp = {
				label: parentRepository.activity_type_name,
				id: parentRepository.activity_type_id,
				value: parentRepository,
			}
			setBreadCrumbMenu([temp])
		}
	}, [parentRepository])

	const onChangeDocs = repository => {
		getFolderContent(repository)
		let menu = breadCrumbMenu
		let temp = {
			label: repository.document_repository_name,
			id: repository.document_repository_id,
			value: repository,
		}
		menu.push(temp)
		setBreadCrumbMenu(menu)
	}
	const onChangeMenu = (repository, index) => {
		let menu = breadCrumbMenu.slice(0, index + 1)
		setBreadCrumbMenu(menu)
		if (index === 0) {
			onSelectRoot(repository.value)
		} else {
			getFolderContent(repository.value)
		}
	}
	const getFolderContent = payload => {
		let params = {
			parent_repository_id: payload.document_repository_id,
			repository_sub_type_id: 0,
			activity_type_id: payload.activity_type_id,
			flag: 2,
			page_start: 0,
			page_limit: 100,
			activity_type_category_id: 61,
		}
		getFolderContentMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					setDocTypes(response)
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const onRefresh = () => {
		if (breadCrumbMenu.length - 1 === 0) {
			refreshPage(parentRepository)
		} else {
			getFolderContent(breadCrumbMenu[breadCrumbMenu.length - 1].value)
		}
	}

	const getSearchedDocs = () => {
		let params = {
			activity_type_type_id: 0, //parentRepository.activity_type_id,
			flag: searchType.value,
			activity_type_category_id: 61,
			start_from: 0,
			limit_value: 50,
			search_string: query,
			parent_doc_repository_id: 0, // parentRepository.document_repository_id,
		}

		getSearchDocsMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					setDocTypes(response)
					// onSearch(false);
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const access =
		authData?.asset_flag_document_repo_super_admin === 1
			? DOCS_ACCESS_TYPES.SUPER_ADMIN
			: !!parentRepository
			? parentRepository.activity_type_access_level_name.toUpperCase()
			: null

	return (
		<>
			{!!parentRepository || breadCrumbMenu.length > 0 ? (
				<HStack
					display='flex'
					bg='whitesmoke'
					my={2}
					mx={1}
					alignItems='flex-start'
					justifyContent='space-between'
				>
					<Breadcrumb
						menu={breadCrumbMenu}
						icon={'/'}
						onSelect={onChangeMenu}
					/>
					<Box mr={16} w='30%'></Box>
				</HStack>
			) : (
				!parentRepository &&
				query.length === 0 && (
					<Flex h='full' w='full' justifyContent='center' alignItems='center'>
						<Center>
							<Text fontSize='2xl'>
								{locale['No document has been selected!']}
							</Text>
						</Center>
					</Flex>
				)
			)}

			{isFoldersLoading || isLoading || isSearchedLoading ? (
				<SimpleGrid columns={4} spacing={4} mx={3}>
					{[1, 2, 3, 4, 5, 6, 7, 8].map(i => {
						return (
							<Skeleton
								boxShadow='md'
								borderRadius='md'
								p={2}
								w='200px'
								h='80px'
							/>
						)
					})}
				</SimpleGrid>
			) : (
				((query && query.length !== 0) || !!parentRepository) && (
					<>
						{!!query && !!searchType ? (
							<Box m={2}>
								<Text>
									{locale['Showing results in']} {searchType.key}s
								</Text>
							</Box>
						) : null}
						{docTypes &&
							!!docTypes.sharedFolderList &&
							!!docTypes.sharedFolderList?.length && (
								<FolderView
									accessableRepositories={accessableRepositories}
									docTypes={docTypes.sharedFolderList}
									folderType={FOLDER_TYPE.SHARED}
									access={access}
									icon={MdFolderShared}
									title='Shared Folder'
									onSelect={onChangeDocs}
									refresh={onRefresh}
									isSearch={query && query.length !== 0}
								/>
							)}
						{docTypes && docTypes.folderList && (
							<FolderView
								accessableRepositories={accessableRepositories}
								docTypes={docTypes.folderList}
								folderType={FOLDER_TYPE.FOLDER}
								icon={MdFolder}
								access={access}
								title='Folders'
								onSelect={onChangeDocs}
								refresh={onRefresh}
								breadCrumbPath={breadCrumbMenu}
								isSearch={query && query.length !== 0}
							/>
						)}

						{docTypes && docTypes.fileList && (
							<FileView
								docTypes={docTypes.fileList}
								icon={MdDescription}
								title='Files'
								access={query && query.length !== 0 ? false : access}
								breadCrumbPath={breadCrumbMenu}
								parent={
									breadCrumbMenu.length > 0 &&
									breadCrumbMenu[breadCrumbMenu.length - 1].value
								}
								folderType={FOLDER_TYPE.FILES}
								// onSelect={onChangeDocs}
								refresh={onRefresh}
								isSearch={query && query.length !== 0}
							/>
						)}
						{!!query &&
						!!docTypes &&
						!docTypes.sharedFolderList.length &&
						!docTypes.folderList.length &&
						!docTypes.fileList.length ? (
							<Flex
								flexDirection='column'
								mt='10'
								align='center'
								justify='center'
							>
								<RiFolderForbidFill size={150} color='#48BB78' />
								<Text color='#48BB78'>{locale['No records found']}</Text>
							</Flex>
						) : null}
					</>
				)
			)}
		</>
	)
}
export default DocumentsView
