import {
	Flex,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Spacer,
	Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdSearch, MdSort } from 'react-icons/md'
import CustomMenu from '../../components/menu'
import TeamExcelFileComp from './TeamExcelFileComp'
import { useLanguageContext } from '../../context/locale.context'
// import { dataLayerTagManager } from '../repository/repo.utils'
export default function TeamHeader({
	filterOptions,
	users,
	selected,
	onChange,
	onSearch,
}) {
	const {
		state: { locale },
	} = useLanguageContext()
	return (
		<>
			<Flex justifyContent='flex-end' alignItems='center' w='full'>
				<Text
					textTransform='uppercase'
					fontSize='16px'
					alignSelf='flex-end'
					fontWeight='bold'
				>
					{selected.label}
				</Text>
				<Spacer />
				<InputGroup flex={1} boxShadow='md' maxW='300px'>
					<Input
						bg='white'
						placeholder={locale['Smart search']}
						borderRadius='md'
						variant='filled'
						onChange={e => {
							onSearch(e.target.value)
						}}
						_focus={{
							border: 'none',
							background: 'white',
						}}
					/>
					<InputRightElement
						children={
							<Icon
								as={MdSearch}
								color={localStorage.getItem('color')}
								w={6}
								h={6}
							/>
						}
					/>
				</InputGroup>
				<CustomMenu
					optionList={filterOptions}
					selected={selected}
					isFilter={true}
					mx={2}
					onSelect={item => {
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Team',
						// 	buttonName: 'Filter',
						// })
						onChange(item)
					}}
					icon={MdSort}
				/>

				<TeamExcelFileComp users={users} />
			</Flex>
		</>
	)
}
