/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CustomMenu from '../../components/menu';
import ReactExport from 'react-data-export';
import { readExcelFile } from '../../utils/readExcelFile';
import { BiDownload } from 'react-icons/bi';

const TeamExcelFileComp = ({ users }) => {
  const [dataSet, setDataSet] = useState([]);
  const callExcelFunction = teamList => {
    readExcelFile(users, setDataSet);
  };
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  useEffect(() => {
    callExcelFunction(users, setDataSet);
  }, [users]);
  return (
    <ExcelFile
      element={
        <CustomMenu
          // onClick={() => callExcelFunction(users,setDataSet)}
          icon={BiDownload}
        />
      }
      filename={'My Team'}
    >
      <ExcelSheet data={dataSet} name={'My Team'}>
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Role" value="asset_type" />
        <ExcelColumn label="Phone No" value="phone" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Segment" value="workforce" />
        <ExcelColumn label="Circle" value="account_name" />
        <ExcelColumn label="Created Date" value="asset_created_datetime" />
        <ExcelColumn label="Last Seen" value="asset_last_seen_datetime" />
        <ExcelColumn
          label="Last Location"
          value="asset_last_location_address"
        />
        <ExcelColumn label="Organization" value="organization_name" />
        <ExcelColumn label="Reporting Manager" value="reporting" />
        <ExcelColumn label="Status" value="asset_flag_suspended" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default TeamExcelFileComp;
